<template>
    <!-- 时间日期选择框 -->
    <el-dialog title="时间日期选择" :visible.sync="open" width="630px" :before-close="handleClose" v-dialogDrag="true">
        <el-form label-width="100px" ref="form" :rules="rules" :model="form">
            <el-form-item label="指定周">
                <div class="x-f">
                    <el-radio-group v-model="form.specialDateItems[0].specialDateWeekMode">
                        <el-radio :label="dict.value" v-for="dict in dict.type.special_date_week_mode" :key="dict.value"
                            :value="dict.value" @click.native.prevent="clickRadio(dict.value)">{{ dict.label }}
                        </el-radio>
                    </el-radio-group>
                    <el-form-item v-if="form.specialDateItems[0].specialDateWeekMode !== '2'">
                        <el-input disabled></el-input>
                    </el-form-item>
                    <div v-if="form.specialDateItems[0].specialDateWeekMode === '2'">
                        <el-form-item :prop="`specialDateItems[${0}].specialDateWeekNum`"
                            :rules="rules[`DateTimeList.specialDateWeekNum`]">
                            <el-input-number style="width: 100px;margin: 0 5px;"
                                v-model="form.specialDateItems[0].specialDateWeekNum" :precision="0" :controls="false" :min="1" :max="6" />
                        </el-form-item>
                    </div>
                    <span>周</span>
                </div>
            </el-form-item>
            <el-form-item label="指定星期">
                <el-checkbox-group v-model="form.specialDateItems[1].specialDateWeeksArr">
                    <el-checkbox label="1">星期一</el-checkbox>
                    <el-checkbox label="2">星期二</el-checkbox>
                    <el-checkbox label="3">星期三</el-checkbox>
                    <el-checkbox label="4">星期四</el-checkbox>
                    <el-checkbox label="5">星期五</el-checkbox>
                    <el-checkbox label="6">星期六</el-checkbox>
                    <el-checkbox label="7">星期日</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="每月期间">
                <div class="marB20 x-f">
                    <el-checkbox label="每月第" v-model="form.specialDateItems[2].isTopMonthPeriod"></el-checkbox>
                    <!-- 显示的文本框 -->
                    <el-form-item v-if="!form.specialDateItems[2].isTopMonthPeriod">
                        <el-input></el-input>
                    </el-form-item>
                    <!-- 实际输入的文本框 -->
                    <!-- 校验 -->
                    <div v-if="form.specialDateItems[2].isTopMonthPeriod">
                        <el-form-item :prop="`specialDateItems[${2}].topFromDay`" :rules="rules[`DateTimeList.topFromDay`]">
                            <el-input class="practical" v-model="form.specialDateItems[2].topFromDay"></el-input>
                        </el-form-item>
                    </div>
                    <!-- </el-form> -->
                    <span>至</span>
                    <!-- 显示的文本框 -->
                    <el-form-item v-if="!form.specialDateItems[2].isTopMonthPeriod">
                        <el-input></el-input>
                    </el-form-item>
                    <!-- 实际输入的文本框 -->
                    <!-- 校验 -->
                    <div v-if="form.specialDateItems[2].isTopMonthPeriod">
                        <el-form-item :prop="`specialDateItems[${2}].topToDay`" :rules="rules[`DateTimeList.topToDay`]">
                            <el-input v-model="form.specialDateItems[2].topToDay"></el-input>
                        </el-form-item>
                    </div>
                    <span>天</span>
                </div>
                <div class="x-f">
                    <el-checkbox label="每月最后" v-model="form.specialDateItems[2].isDownMonthPeriod"></el-checkbox>
                    <!-- 显示的文本框 -->
                    <el-form-item v-if="!form.specialDateItems[2].isDownMonthPeriod">
                        <el-input></el-input>
                    </el-form-item>
                    <!-- 实际输入的文本框 -->
                    <div v-if="form.specialDateItems[2].isDownMonthPeriod">
                        <el-form-item :prop="`specialDateItems[${2}].downFromDay`"
                            :rules="rules[`DateTimeList.downFromDay`]">
                            <el-input v-model="form.specialDateItems[2].downFromDay"></el-input>
                        </el-form-item>
                    </div>
                    <span>至</span>
                    <!-- 显示的文本框 -->
                    <el-form-item v-if="!form.specialDateItems[2].isDownMonthPeriod">
                        <el-input></el-input>
                    </el-form-item>
                    <!-- 实际输入的文本框 -->
                    <div v-if="form.specialDateItems[2].isDownMonthPeriod">
                        <el-form-item :prop="`specialDateItems[${2}].downToDay`" :rules="rules[`DateTimeList.downToDay`]">
                            <el-input v-model="form.specialDateItems[2].downToDay"></el-input>
                        </el-form-item>
                    </div>
                    <span>天</span>
                </div>
            </el-form-item>
            <el-form-item label="指定日期">
                <el-checkbox-group v-model="form.specialDateItems[3].specialDayArr" class="assign">
                    <el-checkbox v-for="item in dateList" :key="item" :label="item" class="checkNum">{{ item }}
                    </el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="指定时段">
                <div class="marB10 x-f" v-for="(item, index) in form.specialDateItems[4].specialTimeItems" :key="index">
                    <el-time-picker is-range v-model="item.timeItem" range-separator="至" start-placeholder="开始时间"
                        end-placeholder="结束时间" placeholder="选择时间范围" value-format="HH:mm:ss">
                    </el-time-picker>
                    <i class="el-icon-delete marL10 pointer" style="color: red; font-size: 16px"
                        @click="deleteTimeHorizon(index)"></i>
                </div>
                <el-button class="marL10" type="text" @click="AddTimeHorizon">新增</el-button>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="getConfirm">确 定</el-button>
            <el-button @click="handleClose">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { deepCopy } from "@/utils";
export default {
    name: 'SelectDate',
    dicts: ['special_date_week_mode'], //周范围
    model: { prop: "oldForm", event: "update:modelValue" },
    props: {
        OpenDate: {
            //开关
            type: Boolean,
            default: false
        },
    },
    computed: {
        open: {
            get() { return this.OpenDate },
            set(e) { this.$emit('update:OpenDate', e) }
        }
    },
    data() {
        return {
            form: {
                specialDateItems: [
                    {
                        columnId: 0,
                        specialDateType: 0,
                        specialDateWeekMode: undefined,
                        specialDateWeekNum: undefined
                    },
                    {
                        columnId: 1,
                        specialDateType: 1, specialDateWeeksArr: []
                    },
                    {
                        columnId: 2,
                        specialDateType: 2,
                        isTopMonthPeriod: false,
                        isDownMonthPeriod: false,
                        topFromDay: undefined,
                        topToDay: undefined,
                        downFromDay: undefined,
                        downToDay: undefined
                    },
                    {
                        columnId: 3,
                        specialDateType: 3,
                        specialDayArr: []
                    },
                    {
                        columnId: 4,
                        specialDateType: 4,
                        specialTimeItems: []
                    }
                ]
            },
            num: 2,
            //日期范围
            dateList: [
                '1',
                '2',
                '3',
                '4',
                '5',
                '6',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
                '20',
                '21',
                '22',
                '23',
                '24',
                '25',
                '26',
                '27',
                '28',
                '29',
                '30',
                '31'
            ],
            //校验
            rules: {
                'DateTimeList.topFromDay': [
                    { required: true, message: '请输入第几天', trigger: ['blur', 'change'] }
                ],
                'DateTimeList.topToDay': [
                    { required: true, message: '请输入第几天', trigger: ['blur', 'change'] }
                ],
                'DateTimeList.downToDay': [
                    { required: true, message: '请输入最后几天', trigger: ['blur', 'change'] }
                ],
                'DateTimeList.downFromDay': [
                    { required: true, message: '请输入最后几天', trigger: ['blur', 'change'] }
                ],
                'DateTimeList.specialDateWeekNum': [
                    { required: true, message: '请输入第几周', trigger: ['blur', 'change'] }
                ]
            }
        }
    },
    methods: {
        setAndGetData(e) {
            this.form.specialDateItems = deepCopy(this.form.specialDateItems).map(x => {
                let v = x
                if (e) {
                    v = e.find(y => Number(y.specialDateType) === Number(x.specialDateType));
                }
                if (v) {
                    // 第一行
                    let dateTime = null;
                    if (v.specialDateWeekMode) {
                        if (v.specialDateWeekMode === '0') {
                            dateTime = '仅单周'
                        } else if (v.specialDateWeekMode === '1') {
                            dateTime = '仅双周'
                        } else if (v.specialDateWeekMode === '2') {
                            dateTime = `仅每月第${v.specialDateWeekNum}周`
                        }
                    } else if (v.specialDateWeeksArr?.length) {
                        // 第二行  (星期转换文字)
                        const arrText = {
                            1: '星期一',
                            2: '星期二',
                            3: '星期三',
                            4: '星期四',
                            5: '星期五',
                            6: '星期六',
                            7: '星期日'
                        }
                        dateTime = v.specialDateWeeksArr.map(y => arrText[y]).join('、')
                    } else if (v.isTopMonthPeriod || v.isDownMonthPeriod) {
                        // 第三行
                        const data = [
                            v.isTopMonthPeriod ? `仅每月第${v.topFromDay}至${v.topToDay}天` : null,
                            v.isDownMonthPeriod ? `每月最后${v.downFromDay}至${v.downToDay}天` : null,
                        ]
                        dateTime = data.filter(y => y).join('，')
                    } else if (Array.isArray(v.specialDayArr) && v.specialDayArr.length) {
                        // 第四行
                        dateTime = `指定日期: 每月${v.specialDayArr.sort((a, b) => Number(a) - Number(b)).join('，')}日`
                    }
                    /*else if (v.specialBegTime) {
                        // 第五行
                        dateTime = `时间范围：${v.specialBegTime} - ${v.specialEndTime}`
                        specialDateType = 4
                    } */
                    else if (Array.isArray(v.specialTimeItems) && v.specialTimeItems.length && v.specialTimeItems.filter(x => x.specialBegTime||x.specialEndTime||x.timeItem).length) {
                        v.specialTimeItems = v.specialTimeItems.filter(x => x.specialBegTime||x.specialEndTime||x.timeItem)
                        v.specialTimeItems = v.specialTimeItems.map(y => {
                            if (y.timeItem?.length === 2) {
                                y.specialBegTime = y.timeItem[0]
                                y.specialEndTime = y.timeItem[1]
                            } else if (
                                y.specialBegTime && y.specialEndTime
                            ) {
                                y.timeItem = [y.specialBegTime, y.specialEndTime]
                            }
                            return y
                        })
                        dateTime = v.specialTimeItems.map(y => `${y.timeItem[0]} - ${y.timeItem[1]}`).join('，')
                    }
                    return { ...x, ...v, dateTime }
                }
                return { ...x, ...v }
            })
            if (e) {
                return this.form.specialDateItems.filter(x => e.find(y => Number(y.specialDateType) === Number(x.specialDateType)))
            }
            return this.form.specialDateItems.filter(x => x.dateTime)
        },
        //对话框关闭的回调
        handleClose() {
            this.reset()
            this.open = false
        },
        //初始化数据
        reset() {
        },
        //确定按钮事件
        getConfirm() {
            this.$refs['form'].validate(async valid => {
                //表单校验
                if (valid) {
                    this.reset()
                    this.$emit('changeDate', this.setAndGetData())
                    this.open = false
                } else {
                    this.$message.error('请把关联的必填项输入完整')
                }
            })
        },
        //指定周单选,再次点击清空
        clickRadio(val) {
            this.form.specialDateItems[0].specialDateWeekMode =
                val === this.form.specialDateItems[0].specialDateWeekMode ? undefined : val
        },
        // 增加指定时间段
        AddTimeHorizon() {
            this.form.specialDateItems[4].specialTimeItems = [...this.form.specialDateItems[4].specialTimeItems, {
                timeItem: '',
                specialBegTime: '',
                specialEndTime: '',
            }]
        },
        // 删除指定时间段
        deleteTimeHorizon(index) {
            this.form.specialDateItems[4].specialTimeItems.splice(index, 1)
        }
    }
}
</script>

<style lang="scss" scoped>
//表单文本显示(向左对齐)
::v-deep.el-form-item__label {
    text-align: left !important;
}

.el-input {
    //文本框宽度
    width: 100px;
    margin: 0 5px;
}

//指定周
.week {
    display: flex;
}

//指定日期
.assign {
    width: 450px;
    border: 1px solid #e6e6e6;
    padding: 20px;
    padding-right: 10px;

    .el-checkbox {
        margin-right: 10px;
    }
}
</style>
