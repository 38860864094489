<template>
  <div>
    <div class="bg-Color marB10 x-f-end padd15">
      <el-button type="primary" size="mini" @click="setMallInformation">保存</el-button>
    </div>
    <el-form :inline="false" :model="form" class="marT10" ref="formRef" label-width="120px">
      <cardTitleCom cardTitle="品牌信息">
        <template #cardContent>
          <div class="padd15">
            <!-- <span class="">品牌信息将在商城主页面的页尾进行展示，优先展示品牌logo</span> -->
            <el-form-item label="分销员申请方式:" class="marT10">
              <el-radio-group v-model="form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11">
                <el-radio label="1">申请人手动申请</el-radio>
                <el-radio label="2">满足条件自动申请</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11 == 1">
              <div style="padding:10px 10px;background-color: #f7f7f7">
              <el-checkbox v-model="form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_12">需要填写申请信息</el-checkbox>
              </div>
            </el-form-item>
            <el-form-item label="商家审核方式:">
              <el-radio-group v-model="form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_13">
                <el-radio label="1">人工审核</el-radio>
                <el-radio label="2">无需审核，满足条件自动通过</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="结算方式:">
              <el-radio-group v-model="form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_14">
                <el-radio label="1">人工结算</el-radio>
                <el-radio label="2">人工结算至会员余额</el-radio>
                <el-radio label="3">自动结算至会员余额</el-radio>
              </el-radio-group>
            </el-form-item>
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import {
  getMallDistributionCommissionConfig, saveUpMallDistributionCommissionConfig
} from '@/api/O2OMall/config/sysConfig'
export default {
  name: 'shoppengMessage',
  components: {
    cardTitleCom,
  },
  data() {
    return {
      form: {
        mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11: '1',
        mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_12: false,
        mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_13: '1',
        mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_14: '1'
      },
    }
  },
  created() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      getMallDistributionCommissionConfig().then(res => {
        this.form = res.data || {}
      })
    },
    setMallInformation() {
      saveUpMallDistributionCommissionConfig(this.form).then(res => {
        this.$message.success(res.msg)
      })
      this.getDetails()
    }
  }
}
</script>

<style scoped>
</style>
