var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container x-start" }, [
    _c(
      "div",
      { staticClass: "leftBox padd15" },
      _vm._l(_vm.leftBtnList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "item x-c cursorP",
            class: { clickStyle: _vm.leftItem == index },
            on: {
              click: function ($event) {
                return _vm.itemClick(index)
              },
            },
          },
          [_vm._v(" " + _vm._s(item) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "rightBox marL10",
        staticStyle: { "overflow-y": "scroll" },
      },
      [
        _vm.leftItem == 0
          ? _c("shopping-setting")
          : _vm.leftItem == 1
          ? _c("deal-setting")
          : _vm.leftItem == 2
          ? _c("after-sale-setting")
          : _vm.leftItem == 3
          ? _c("freight-configuration")
          : _vm.leftItem == 4
          ? _c("liveSetting")
          : _vm.leftItem == 5
          ? _c("shoppengMessage")
          : _vm.leftItem == 6
          ? _c("distribution")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }