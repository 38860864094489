var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "bg-Color marB10 x-f-end padd15" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "mini" },
              on: { click: _vm.setMallInformation },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "el-form",
        {
          ref: "formRef",
          staticClass: "marT10",
          attrs: { inline: false, model: _vm.form, "label-width": "120px" },
        },
        [
          _c("cardTitleCom", {
            attrs: { cardTitle: "品牌信息" },
            scopedSlots: _vm._u([
              {
                key: "cardContent",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "padd15" },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "marT10",
                            attrs: { label: "分销员申请方式:" },
                          },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("申请人手动申请"),
                                ]),
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v("满足条件自动申请"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form
                          .mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_11 ==
                        1
                          ? _c("el-form-item", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    padding: "10px 10px",
                                    "background-color": "#f7f7f7",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox",
                                    {
                                      model: {
                                        value:
                                          _vm.form
                                            .mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_12,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_12",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_12",
                                      },
                                    },
                                    [_vm._v("需要填写申请信息")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _c(
                          "el-form-item",
                          { attrs: { label: "商家审核方式:" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_13,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_13",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_13",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("人工审核"),
                                ]),
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v("无需审核，满足条件自动通过"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "结算方式:" } },
                          [
                            _c(
                              "el-radio-group",
                              {
                                model: {
                                  value:
                                    _vm.form
                                      .mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_14,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_14",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "form.mall_SYSTEM_CONFIG_MALL_DISTRIBUTION_COMMISSION_14",
                                },
                              },
                              [
                                _c("el-radio", { attrs: { label: "1" } }, [
                                  _vm._v("人工结算"),
                                ]),
                                _c("el-radio", { attrs: { label: "2" } }, [
                                  _vm._v("人工结算至会员余额"),
                                ]),
                                _c("el-radio", { attrs: { label: "3" } }, [
                                  _vm._v("自动结算至会员余额"),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }